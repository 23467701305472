html, body, #root, .container {
  height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.container {
  background-color: #f2f7f4;
  padding: 3rem 2rem 0;
  position: relative;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-control, .btn{
  border-radius: 0;
  padding: .75rem .75rem;
  height: 3rem;
}

.alert {
  border-radius: 0;
}
.alert-success{
  background-color: rgb(91, 133, 81);
  color: white;
}
.alert-danger{
  background-color: #80433e;
  color: white;
}
.holiday-box{
  padding-top: 1rem;
  background-color: #5c5c5c;
  border-radius: 6px; 
  color: white;
}
.holiday-box p{
  margin-bottom: .5rem;
}
.btn-custom {
  background-color: #5c5c5c;
  border-color: #5c5c5c;
  color: white;
}
.btn-custom:hover {
  background-color: #4d4d4d;
  color: white;
}
label {
  font-weight: bold;
}
hr {
  border-top: 1px solid rgba(0,0,0,.2);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
/* .active-holiday{
  background-color: rgb(91, 133, 81);
} */
.btn-light{
  background-color: #dedede;
}
.ReactCollapse--collapse {
  transition: height 250ms;
  color: black;
}
.ReactCollapse--content{
  padding: 1rem;
}
.holidays > a, .holidays > a:hover{
  text-decoration: none;
  color: black;
  padding: .75rem;
}
.holidays {
  background-color: #dedede;
}
.info-box{
  background-color: #dedede;
  padding: .75rem;
}
.holiday-button{
  width: 50%;
  display: inline-block;
  padding:.5rem;
  color: white;
  text-decoration: none;
  transition: width .2s;
}
.holiday-button.active{
  width: 85%;
}
.holiday-button.inactive{
  width: 15%;
  pointer-events: none;
  opacity: .5;
}
.holiday-box .holiday-button:nth-child(even){
  background-color: #80433e;
  border-radius: 0 0 6px; 
}
.holiday-box .holiday-button:nth-child(even):hover, .holiday-box .holiday-button:nth-child(odd):hover{
  color: white;
}
.holiday-box .holiday-button:nth-child(odd){
  background-color: rgb(91, 133, 81);
  border-radius: 0 0 0 6px; 
}
.weekday-wrapper {
  display: flex;
  justify-content: space-between;
}
.weekday-button{
  padding: .5rem 2rem;
  border: none;
  background-color: #5c5c5c;
  color: white;
  flex: 1;
}
.weekday-button.active{
  background-color: rgb(91, 133, 81);
}
.weekday-button:not(:nth-child(0)):not(:nth-child(7)){
  border-right: 1px solid white;
}
.loader {
  width: 100%;
  position: absolute;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
}